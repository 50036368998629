<template>
  <div class="warp">
    <div class="left">
      <div class="flex-center">
        <el-button class="add" type="primary" icon="el-icon-circle-plus" v-if="isAuth('platform-config:tbindustrys:save')" size="small" @click="openType()">
          新增一级类目</el-button>
      </div>
      <el-scrollbar style="height:calc(100% - 80px)">
        <div class="typeContent">
          <ul class="type">
            <li :class="{'active':active===item.industryName}" @click="changeActive(item)" v-for="item in typeList" :key="item.id">
              <div class="flex_bet">
                <div class="w190 ellipsis" :title="item.industryName">{{item.industryName}}</div>
				
                <div v-if="item.industryCode">
                  <img @click.stop="openType(item)" v-if="isAuth('platform-config:tbindustrys:update')" src="@/assets/img/icon/icon_edit.png">
                  <img @click.stop="delOne(item)" v-if="isAuth('platform-config:tbindustrys:delete')" class="ml10" src="@/assets/img/icon/icon_delete.png">
                </div>
              </div>
              <span>
              </span>
            </li>
          </ul>
        </div>
      </el-scrollbar>
    </div>
    <div class="right">
      <el-scrollbar style="height:100% ">
        <jf-layout class="content">
          <template slot="head">
            <el-button size="small" type="primary" icon="el-icon-circle-plus" v-if="isAuth('platform-config:tbindustrys:save')" @click=" addTwo">新增二级类目
            </el-button>
            <el-button size="small" type="primary" v-if="isAuth('platform-config:tbindustrys:import')" icon="el-icon-download">导入</el-button>
            <!-- <el-button size="small" type="danger" icon="el-icon-delete">删除</el-button> -->
          </template>

          <el-table v-loading="loading" slot="body" border :data="list">
            <el-table-column align="center" type="selection" width="50" />
            <el-table-column align="center" prop="industryName" label="名称" />
            <el-table-column align="center" label="操作" width="250">
              <template slot-scope="{row}">
                <el-button size="mini" icon="el-icon-edit" v-if="isAuth('platform-config:tbindustrys:update')" @click="edit(row)">编辑</el-button>
                <el-button size="mini" icon="el-icon-delete" v-if="isAuth('platform-config:tbindustrys:delete')" @click="remove(row)" type="danger">删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <jf-page slot="foot" :total="total" :page.sync="params.page" :limit.sync="params.limit" @change="getList" />

        </jf-layout>
      </el-scrollbar>

      <AddOne @submit="getType" :show.sync="addOneShow" :value="typeVal" />
      <AddTwo @edit="getList" @add="search" :row="row" :parentCode="parentCode" :show.sync="addTwoShow" />
    </div>
  </div>
</template>
<script>
import AddOne from "./addOne";
import AddTwo from "./addTwo";
export default {
  components: {
    AddOne,
    AddTwo,
  },
  data() {
    return {
      addOneShow: false,
      addTwoShow: false,
      active: "全部行业类型",
      activeItem: {
        parentCode: "0",
      },
      list: [],
      typeList: [],
      total: 0,
      params: {
        page: 1,
        limit: 10,
        orderField: "createDate",
      },
      loading: false,
      row: {},
      parentCode: "", //传给子组件的父级id
      typeVal: {},
    };
  },
  created() {
    this.getType();
    this.getList();
  },
  mounted() {},
  methods: {
    addTwo() {
      this.parentCode = this.activeItem.industryCode;
      this.row = {};
      this.addTwoShow = true;
    },
    edit(row) {
      this.parentCode = this.row.parentCode;
      this.row = row;
      this.addTwoShow = true;
    },
    changeActive(item) {
      this.active = item.industryName;
      this.activeItem = item;
      this.search();
    },
    search() {
      this.params.page = 1;
      this.getList();
    },
    // 打开 一级类型编辑弹窗
    openType(data) {
      this.addOneShow = true;
      if (data) {
        this.typeVal = data;
      } else {
        this.typeVal = null;
      }
    },
    // 删除  一级类型
    delOne(row) {
      this.$confirm("确认删除选择的数据", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          var res = await this.$del(`/platform-config/industry/${row.id}`);

          if (res && res.code === 0) {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.getType();
            this.getList();
          }
        })
        .catch(() => {});
    },
    async getType() {
      var res = await this.$get("/platform-config/industry/tree");
      if (res && res.code === 0) {
        this.typeList = res.data || [];
        this.typeList.unshift({
          industryName: "全部行业类型",
          parentCode: "0",
        });
        this.active = "全部行业类型";
      }
    },

    async getList() {
      this.loading = true;
      this.params.parentCode = this.activeItem.industryCode || "0";
      var res = await this.$get("/platform-config/industry/page", this.params);
      this.loading = false;
      if (res && res.code === 0) {
        this.list = res.data.list;
        this.total = res.data.total;
      }
    },
    remove(row) {
      this.$confirm("确认删除选择的数据", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          var res = await this.$del(`/platform-config/industry/${row.id}`);

          if (res && res.code === 0) {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.getList();
          }
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped lang="scss">
.warp {
  width: 100%;
  height: calc(100vh - 163px);
  max-height: calc(100vh - 163px);
  display: flex;

  /deep/ .el-scrollbar .el-scrollbar__wrap {
    overflow-x: hidden;
  }

  .left {
    width: 300px;
    min-width: 300px;
    border-right: 1px solid #eee;
    padding: 0px;

    .typeContent {
      padding: 0 20px 50px 20px;
    }

    .add {
      margin: 20px;
    }

    .title {
      margin: 0 0 0 0;
      font-size: 16px;
      margin-top: 15px;
      padding-left: 15px;
    }

    ul.type {
      list-style: none;
      padding: 20px 0 0 0px;

      li {
        padding-left: 15px;
        line-height: 36px;

        // border-bottom: 1px solid #eee;
        &:hover {
          cursor: pointer;
        }

        &.active {
          background: rgb(223, 239, 255);
        }

        span {
          float: right;

          i {
            margin: 0 3px;

            &:hover {
              opacity: 0.7;
            }
          }

          .el-icon-edit {
            color: blue;
          }

          .el-icon-delete {
            color: red;
          }
        }
      }
    }
  }

  .right {
    flex: 1;
    padding: 0px;

    .content {
      padding: 20px;
    }
  }
}

.w190 {
  width: 190px;
}

.ml10 {
  margin-left: 10px;
}
</style>
